import React, { Component } from 'react';
import Navbar from './navbar'
import "../style/home.css";


class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    render() { 
        return ( 
        <div>
        <div className='container'>
            <h3 className='welcome'>
                Hello, my name is Dominic Smorra. This May I graduated from Quinnipiac University with a B.S. in Software Engineering. Feel free to check out some links to my social media profiles on the bottom of the page.
            </h3>
                
        </div> 
        </div> );
    }
}
 
export default Home;