import React, { Component } from "react";
// import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter, Switch, Route, Link } from "react-router-dom";
import "./App.css";
import Navbar from './components/navbar'
import Home from './components/home'
import Footer from './components/footer'

import 'primeicons/primeicons.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.css';
import 'primeflex/primeflex.css';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null
    }
  }
  
  render() {
    const { user } = this.state;
    console.log("App.js state = " + user);
    return (
      <div className = "App">
        {/* <Navbar /> */}
        <BrowserRouter >
        <Switch>
          <Route exact path = "/" render={(props) => <Home  {...props} />}></Route>
        </Switch> 
        </BrowserRouter>
        <Footer />
      </div>
    );
  }
}

export default App;

