import React, { Component } from 'react';
import { Icon } from 'semantic-ui-react'
import "../style/navbar.css";

import ReactDOM from 'react-dom';
import { Menu } from 'primereact/menu';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';

export class Navbar extends Component {

    constructor(props) {
        super(props);

        this.items = [
            {
                label: 'Options',
                items: [
                    {
                        label: 'Update',
                        icon: 'pi pi-refresh',
                        command: () => {
                            this.toast.show({ severity: 'success', summary: 'Updated', detail: 'Data Updated', life: 3000 });
                        }
                    },
                    {
                        label: 'Delete',
                        icon: 'pi pi-times',
                        command: () => {
                            this.toast.show({ severity: 'warn', summary: 'Delete', detail: 'Data Deleted', life: 3000 });
                        }
                    }
                ]
            },
            {
                label: 'Navigate',
                items: [
                    {
                        label: 'React Website',
                        icon: 'pi pi-external-link',
                        url: '/'
                    },
                    {
                        label: 'Router',
                        icon: 'pi pi-upload',
                        command:(e) => {
                            window.location.hash = "/fileupload"
                        }
                    }
                ]
            }
        ];
    }

    render() {
        return (
            <div className='menu'>
                <Toast ref={(el) => { this.toast = el; }}></Toast>
                <div >
                    <Menu model={this.items} popup ref={el => this.menu = el} id="popup_menu" />
                    <Button style={{backgroundColor:'#EBCB5D', border:'black'}} icon="pi pi-bars" onClick={(event) => this.menu.toggle(event)} aria-controls="popup_menu" aria-haspopup />
                </div>
            </div>
        )
    }
}

export default Navbar