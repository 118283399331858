import React, { Component } from 'react';
import { SocialIcon } from 'react-social-icons';
import '../style/footer.css'
class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    render() { 
        return (
            <div className='icons'>
        <SocialIcon style={{margin: '5px'}} className='fa-linkedin' url="https://www.linkedin.com/in/dominicsmorra/" /> 
         <SocialIcon style={{margin: '5px'}} className='fa-instagram' url="https://www.instagram.com/dominic_smorra/?hl=en" />
         <SocialIcon style={{margin: '5px'}} className='fa-github' url="https://github.com/dominicsmorra" />
        </div>
        );
    }
}
 
export default Footer;